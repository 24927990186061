import { inject }                 from 'aurelia-framework';
import { UserPassword }           from 'modules/core/models/user-password';
import { RolesRepository }        from 'modules/administration/roles/services/repository';
import { UserStatusesRepository } from 'modules/administration/user-statuses/services/repository';

@inject(RolesRepository, UserStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param rolesRepository
     * @param userStatusesRepository
     */
    constructor(rolesRepository, userStatusesRepository) {
        this.rolesRepository        = rolesRepository;
        this.userStatusesRepository = userStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {UserPassword}
     */
    model() {
        let model = new UserPassword();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns `user info` form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    userInfoSchema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.name',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.email = {
            type:       'text',
            key:        'email',
            label:      'form.field.email',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.role_ids = {
            type:         'multiselect-native',
            key:          'role_ids',
            label:        'form.field.role',
            size:         4,
            remoteSource: this.rolesRepository.active.bind(this.rolesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.created_at = {
            type:       'text',
            key:        'created_at',
            label:      'form.field.created-at',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.last_login_at = {
            type:       'text',
            key:        'last_login_at',
            label:      'form.field.last-login-at',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            remoteSource: this.userStatusesRepository.active.bind(this.userStatusesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.name, this.email, this.role_ids],
            [this.created_at, this.last_login_at, this.status_id],
        ];
    }

    /**
     * Returns `change password` form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    changePasswordSchema(viewModel) {
        this.password = {
            type:  'password',
            key:   'password',
            label: 'form.field.password',
            size:  4,
        };

        this.password_confirmation = {
            type:  'password',
            key:   'password_confirmation',
            label: 'form.field.password-confirmation',
            size:  4,
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.password, this.password_confirmation],
            [this.buttons],
        ];
    }
}
