import { inject }          from 'aurelia-framework';
import { AppContainer }    from 'resources/services/app-container';
import { UsersRepository } from 'modules/administration/users/services/repository';
import { FormSchema }      from 'modules/core/user-profile/form-schema';
import { BaseViewModel }   from 'base-view-model';

@inject(AppContainer, UsersRepository, FormSchema)
export class ViewUserProfile extends BaseViewModel {

    userInfoSchema       = {};
    changePasswordSchema = {};
    changePasswordAlert  = {};

    authenticatedUser;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.userInfoSchema       = this.formSchema.userInfoSchema(this);
        this.changePasswordSchema = this.formSchema.changePasswordSchema(this);
        this.initialModel         = this.formSchema.model();
        this.model                = this.formSchema.model();

        return this.fetchData(params);
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();
    }

    /**
     * Submits view form
     */
    submit() {
        this.changePasswordAlert = null;

        // calls repository create method
        this.repository.changePassword(this.model)
            .then((response) => {
                this.resetForm();

                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    return this.appContainer.notifier.successNotice(response.message);
                }
            });
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return this.repository.authenticatedUser().then((response) => {
            this.authenticatedUser = response;

            this.authenticatedUser.role_ids = this.authenticatedUser.roles.map((role) => role.id);
        });
    }

    /**
     * Resets form fields
     */
    resetForm() {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
                this.model.assign(this.initialModel);
                resolve(true);
                reject(new Error('Error'));
            },
        );
    }
}
